@import url("//db.onlinewebfonts.com/c/8e1f2a0617863e3914ef2ccdab13480c?family=Centaur");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

.view-announce-main-heading {
    font-size: 40px;
    font-family: 'Centaur';
    font-weight: bolder;
}

.blog-card .card-body {
    position: relative;
    top: -30px;
    z-index: 1;
    background: #fff;
    width: 90%;
    box-shadow: 0px 0px 10px rgba(86, 68, 169, .1);
}

.card-title {
    font-family: 'Centaur';
    font-weight: 700;
}

.card-h {
    height: 40px;
}

.medical-card-heading {
    font-family: 'Centaur';
    font-weight: 600;
    font-size: 18px;
}

.medical-card-sub-heading {
    font-family: 'Poppins';
    font-size: 0.8rem;
}

.text-link {
    padding: 0;
    color: var(--primary-color) !important;
    text-decoration: none;
    border: white;
}

.text-link:hover {
    padding: 0;
    color: var(--primary-color) !important;
    text-decoration: none;
    border: white;
    background-color: white !important;
}

.view-announce-card-1 {
    display: flex;
    gap: 100px;
}