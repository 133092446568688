/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.__PrivateStripeElement{
    width:30vw !important
}
@media only screen and (max-width: 576px) {
    .__PrivateStripeElement{
        width:80vw !important
    }  

    .subs{
        margin: 0 5% !important 
    }
}

.subs{ width: 100%;
     height: 50vh;
      margin: 0 35%
     }
.StripeElement {
    height: 40px !important;
    padding: 10px 12px !important;
    width: 100% !important;
    color: #32325d !important;
    background-color: white !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }