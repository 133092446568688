
@import url("//db.onlinewebfonts.com/c/8e1f2a0617863e3914ef2ccdab13480c?family=Centaur");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');


.cancer-card-main-heading {
    font-size: 40px;

    font-weight: bolder;

}

.cancer-card-series-1,
.cancer-card-series-2 {
    display: flex;
    gap: 100px;
}


.cancer-card-heading {
    font-family: 'Centaur';
    font-size: 25px;
    font-weight: 700;
}



.cancer-card-sub-heading {
    font-family: 'Poppins';
}