.mainmenu-nav .nav-link {
    color: #192335;
    display: flex;
    align-items: center;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: 500;
}

.mainmenu-nav .nav-link:hover {
    color: var(--primary-color);
}

.dropdown-item:hover{
    background-color: #b8353b !important;
    color: white !important;
}

.navbar {
    width: 100%;
    z-index: 10;
    background-color: #f3f3f3;
}

.nav-grad {}

@media (max-width: 991px) {
    .mainmenu-nav .navbar-collapse {
        position: absolute;
        top: 115%;
        background: #fff;
        width: 100%;
        left: 0;
        box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
    }

    .mainmenu-nav .nav-link {
        height: 48px;
        line-height: normal;
    }
}

@media (min-width: 992px) {
    .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}


#collasible-nav-dropdown {
    font-weight: bold;
}


.menubar .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    background-color: #0000 !important;
    border: #0000 !important;

}

.header-link a{
    
    text-decoration: none;
}

.header-link{
    text-transform: capitalize !important;
}

.dropdown-item:active{
    background-color: rgb(184, 53, 59);
}


.menubar .button-p{
    background-color: #0000 !important;
    border: #0000 !important;
    color: black !important;
    font-weight: 500 !important;
    font-size: var(--bs-nav-link-font-size);
}

.menubar .dropdown-toggle::after {
    color: black;
}

