.news-title{
    background-color: var(--secondary-color);
    padding: 10px 0px;
    color: #fff;
    width: 20%;

}

#google_translate_element{
    overflow-x: hidden !important;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd{
    display: none !important;
}

.skiptranslate{
    color: white !important;
    /* height: 10px !important; */
}

.goog-te-comboa{
    height: 35px !important;
}

.a-text{
    font-size: 13px;
}

@media only screen and (max-width: 1200px) {
    .news-title{
        width: 24% ;
    }
}

@media only screen and (max-width: 1180px) {
    .m-mobile{
        /* margin: 0 !important; */
        padding:0px  0 !important;
    }

    .a-text{
        font-size: 9px !important;
    }

    .pl-mobile{
        margin-left:10px !important;
    }

    .pr-mobile{
        margin-right:10px !important;
    }

    .m-float{
        float: right !important;
    }
    .news-title{
        width: 15% !important;
    }
}

#nt-title {
    list-style: none;
    margin: 0; 
    padding: 0;
}
#nt-title li {
    list-style: none;
}

#nt-title li div {
    color: #4e4e4e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.langauge .dropdown-menu{
    min-width:52px
}
.social{
    display: flex;
    align-items: center;
}
.social a {
    font-size: 1rem;
    transition: all .2s ease-in-out;
    transform: translateY(0);
    margin: 0 0.4rem 0 0;
}



.news-ticker {
    display: flex;
    width:86%;
    overflow: hidden;
}

.news-item {
    animation: ticker 18s infinite linear;
    white-space: nowrap;
    width: 100%;
}

@keyframes ticker {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}