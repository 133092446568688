@import url("//db.onlinewebfonts.com/c/8e1f2a0617863e3914ef2ccdab13480c?family=Centaur");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');


.card-series-1 {
    
}

.medical-center-heading {
    font-size: 40px;
    font-family: 'Centaur';
    font-weight: bolder;
}


.card-heading {
    font-family: 'Centaur';
    font-size: 25px;
}

.active>.page-link{
    color:white !important;
    border-color:brown;
    background-color: brown !important;
}
.page-item a{
    color: black !important;
}

.medical-center-subheading {
    font-family: 'Poppins';
    font-size: larger;
    font-weight: 500;
}

.card-sub-heading-view-medical {
    font-family: 'Poppins';
}