@import url("//db.onlinewebfonts.com/c/8e1f2a0617863e3914ef2ccdab13480c?family=Centaur");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');



.line {
    height: 1px;
    background-color: #EEEEEE;
    width: 100%;
    margin: 35px 0px;
}

/* #medical-center-info-page_1 .slick-list{

} */
.cardc {
    width: 100%;
    /* margin: auto; */
}

.user-img {
    width: 100% ;
    height: 140px ;
    /* border-radius: 50%; */
    cursor: pointer;
}


.user-round-img {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
    cursor: pointer;
}
.d-text{
    font-size: 10px;
}

.d-header{
    font-size: 18px;
}
.fa-star.active {
    color: #E91E63;
}

.btn-pink {
    background-color: #E91E63;
    color: #fff;
    height: 70px;
    padding: 20px 30px;
    margin-top: 15px;
}

.btn-pink:hover {
    background-color: #D81B60;
}


#medical-center-info-page_1 .line-primary-btn.active{
    background-color: #b8353b !important;
    color: white;
    padding: 10px 50px  !important;
    border-color: #b8353b !important;
} 

#medical-center-info-page_1 .line-primary-btn:hover{
    background-color: #b8353b !important;
    color: white;
    padding: 10px 50px  !important;
    border-color: #b8353b !important;
} 


.image-bg {
    width: 100px;
}

.fit-image {
    object-fit: cover;
    width: 100%;
}

.prod-pic {
    width: 80px;
    height: 100px;
    cursor: pointer;
}

.prod-bg {
    width: 19.5%;
    height: 110px;
    background-color: #E0E0E0;
    margin-bottom: 10px;
}

.fat-img {
    width: 94px;
    height: 100px;
}

.more {
    width: 19.5%;
    height: 110px;
    color: #fff;
    background-color: #000;
    cursor: pointer;
}

@media screen and (max-width: 677px) {
    .cardc {
        width: 100%;
        margin: auto;
    }

    .btn-pink {
        width: 100%;
        height: 40px;
        padding: 6px 30px;
    }

    .prod-bg {
        width: 33%;
        height: 110px;
        background-color: #E0E0E0;
    }

    .more {
        width: 66%;
    } 

    .my-modal {
        width:95vw !important;    /* Occupy the 90% of the screen width */
        max-width: 95vw !important;
    } 
    
}

/* -----------------  */
.sliderblock {
    text-decoration: none;
}

.sliderblock,
.image-block {
    overflow: hidden;
}


.date-input{
    width: 100% !important;
}

.my-modal {
    width: 45vw;    /* Occupy the 90% of the screen width */
    max-width: 45vw;
} 


.sliderblock .medicalslideheight {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sliderblock:hover .medicalslideheight {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
} 


.medical-img-intro {
    display: flex;
}


.nav-tabs{
    background-color: white;
    border-radius: 0 !important;
    color: #b8353b;
    border: 1PX solid #b8353b;
    padding: 10px 50px !important;
}

.nav-tabs.active{
    background-color: #b8353b !important;
    color: white !important;
    padding: 10px 50px ;
    border: 1PX solid #b8353b !important;
}

.nav-tabs:active{
    background-color: #b8353b !important;
    color: white !important;
    padding: 10px 50px  ;
    border: 1PX solid #b8353b !important;
}

.nav-tabs:hover{
    background-color: #b8353b !important;
    color: white !important;
    border: 1PX solid #b8353b;
}
   


.img-gallery-slider {
    width: 100px;
    height: 70px;
    margin-right: 5px;
    
}

.medical-center-menu-bar {
    margin-right: 30px;
}

.round-left{
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;

}


.round-right{
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.menu-bar-info{
    font-family: 'Poppins';
}

.menu-bar-info-1 {
    font-family: 'Poppins';
    font-size: 20px;
    width: 220px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    /* margin-right: 20px; */
}

.menu-bar-info-2 {
    font-family: 'Poppins';
    font-size: 20px;
    width: 220px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /* margin-right: 20px; */
}

.menu-bar-info-3 {
    font-family: 'Poppins';
    font-size: 20px;
    width: 220px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /* margin-right: 20px; */
}




.centerInfoThumbnails .slick-slide div{
    margin-left: 5px;
}

.centerInfoThumbnails .slick-next:before, .centerInfoThumbnails .slick-prev:before{
    position: absolute;
    color: #fff;
    top: 50%;
    transform: translateY(-50%) scale(0.5);
}
.centerInfoThumbnails .slick-next:before{
    left: 0;
}
.centerInfoThumbnails .slick-prev:before{
    right: 0;
}

.medical-doctor-menu-card-1 {
    display: flex;
    column-gap: 10px;
}



@media only screen and (max-width: 576px) {
    .nav-tabs{
        padding:8px 20px !important;
    }
    .nav-tabs:active{
        padding:8px 20px!important;
    }
  .nav-tabs:active{
        padding:8px 20px !important;
    }

    .nav-tabs:hover{
        padding:8px 25px!important;
    }
    .user-img {
        width: 100px !important;
        height: 100px !important;
        /* border-radius: 50%; */
        cursor: pointer;
    }
    .doctor-section{
        text-align: center !important;
    }
    
}
