@import url("//db.onlinewebfonts.com/c/8e1f2a0617863e3914ef2ccdab13480c?family=Centaur");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');



.view-destination-main-heading {
    font-size: 40px;
    font-family: 'Centaur';
    font-weight: bolder;
}



.sliderd {
    width: 55% ;
    margin: auto !important;
    text-decoration: none;
}
@media only screen and (max-width: 576px) {
    .sliderd {
        width: 100% !important;
    }
}

.fd-content {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.fd-bottom-content {
    text-align: center;
    width: 100%;
}

.destination-country {
    font-family: var(--heading--font-family);
    color: white;
    top: 270px;
    left: 100px;
}

.destination-centers {
    font-family: 'Poppins';
}