

.hospital-profile .profile-img{
    text-align: center;
}
.hospital-profile .profile-img img{
    width: 300px;
    height: 200px;
}
.hospital-profile .profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -10%;
    width: 80%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
}

.hospital-profile .profile-work{
    margin: 15px;
    padding: 20px;
  
}

.hospital-profile .nav-last{
    border-radius: 0 0 8px 8px !important;
}
.hospital-profile .nav-first{
    border-radius: 8px 8px 0 0!important;
}

.hospital-profile .nav-first:hover{
    border-radius: 8px 8px 0 0!important;
}


.hospital-profile .profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
}

.hospital-profile .form-control:focus {
    box-shadow: none;
    border-color: #b8353b
}

.hospital-profile .profile-button {
    background: #b8353b;
    box-shadow: none;
    border: none
}

.hospital-profile  .profile-button:hover {
    background: #b8353b
}

.hospital-profile  .profile-button:focus {
    background: #b8353b;
    box-shadow: none
}

.hospital-profile  .profile-button:active {
    background: #b8353b;
    box-shadow: none
}

.hospital-profile  .back:hover {
    color: #b8353b;
    cursor: pointer
}

.hospital-profile  .labels {
    font-size: 11px
}

.add-experience:hover {
    background: #b8353b;
    color: #fff;
    cursor: pointer;
    border: solid 1px #b8353b
}




