

.field1-2 {
    display: flex;
    gap: 50px;
}

.message {
    text-align: left;
    /* margin-bottom: 50px; */
}