@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import url("//db.onlinewebfonts.com/c/8e1f2a0617863e3914ef2ccdab13480c?family=Centaur");
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "react-toastify/dist/ReactToastify.css";
@import 'react-gallery-carousel/dist/index.css';
:root {
    --text-white: #fff;
    --dark-color: #161921;
    --body-text-color: #687179;
    --body-font-size: 0.875rem;
    --body-font-family: 'Poppins';
    --heading--font-family: 'Centaur';
    --body-color: #fff;
    --primary-color: #b8353b;
    --secondary-color: #04abce;
    --radius-small: 4px;
    /* dashboard */

    
    --main-bg: #ffffff;
    --second-bg: #fafafb;
    --txt-color: #455560;
    --txt-white: #fff;
    --main-color: #b8353b;
    --second-color: #b8353b;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    --main-bg-light: #ffffff;
    --second-bg-light: #fafafb;
    --txt-color-light: #455560;
    --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    --main-bg-dark: #2d2d2d;
    --second-bg-dark: #202020;
    --txt-color-dark: #bbbbbb;
    --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

    --main-color-blue: #b8353b;
    --second-color-blue: #b8353b;

    --main-color-red: #fb0b12;
    --second-color-red: #ff4a6b;

    --main-color-cyan: #10d4d2;
    --second-color-cyan: #2ae9e6;

    --main-color-green: #019707;
    --second-color-green: #4caf50;

    --main-color-orange: #d68102;
    --second-color-orange: #fca11a;

    --sidebar-width: 300px;
    --border-radius: 15px;
    --topnav-height: 110px;
    --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


td,th{
text-transform: none;
}


*,
*::before,
*::after {
    box-sizing: border-box
}


@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth
    }
}


/* html,
body {
    overflow-x: hidden;
} */



body {
    margin: 0;
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    font-weight: 400;
    background-color: var(--body-color);
    overflow-x: hidden !important;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

/* body,
html,
#root {
    height: 100%;

} */


/* .VIpgJd-ZVi9od-ORHb-OEVmcd{
    display: none;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    font-family: var(--heading--font-family);
}

a {
    color: var(--text-color);
}

a:hover {
    text-decoration: none;
    color: var(--primary-color);
}

.red-link {
    color: var(--primary-color);
    text-decoration: none;
}

.red-link:hover {
    text-decoration: none !important;
    color: var(--primary-color)
}

.font-size-lg {
    font-size: 1.125rem ;
}

/* .sliderblock .card-img-top {
    width: 100% !important;
} */
.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: var(--radius-small);
    color: var(--text-white) !important;
}

  
.btn-primary:active {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;

}



/* #react-select-30-input{
    margin-top: 30px !important;
} */

.css-1nmdiq5-menu{
    margin-top: 25px !important;
}

.btn-primary:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.text-black {
    color: #000 !important;
}

.font-w500 {
    font-weight: 500;
}

.fs-14 {
    font-size: 0.875rem;
    line-height: 1.5;
}

.fs-18 {
    font-size: 1.125rem !important;
    line-height: 1.5;
}

.slick-dots li button:before {
    content: none;
}

.slick-dots li button {
    border: 2px solid var(--primary-color);
    border-radius: 100%;
}

.slick-dots li.slick-active button {
    border: 2px solid var(--primary-color);
    background-color: var(--primary-color);
}

.slick-prev:before {
    content: url(../public/images/less-then.png) !important;
}

.slick-next:before {
    content: url(../public/images/greater-then.png) !important;
}

.line-primary-btn,
.line-primary-btn.nav-link {
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.line-primary-btn.nav-link {
    border: 1px solid !important;
}

.line-primary-btn:hover,
.line-primary-btn.nav-link:hover,
.line-primary-btn.nav-link.active {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

@media only screen and (width: 1180px) and (height: 820px) {
    .spltext{
        margin-top: 100px !important; 
    }
    .ab-section {
        height: 100% !important;
        padding: 50px 0 !important;
    }
}

@media only screen and (width: 820px) and (height: 1180px) {
    .spltext{
        margin-top: 100px !important; 
    }
    .ab-section {
        height: 100% !important;
        padding: 50px 0 !important;
    }
}
@media (min-width:481px) {
    .sliderblock {
        width: 95% !important;
    }
}

@media (max-width:480px) {

    /* .list-inline-item:not(.list-inline:nth-child(3)) { 
        margin-right: 0.5rem;
    }
    .list-inline-item:not(.list-inline:nth-child(6)) { 
        margin-right: 0.5rem;
    } */
    .icon-box {
        width: 114px;
    }

    .home-main-intro .pb-9 {
        padding-bottom: 2.375rem !important;
    }


}

@media (min-width:768px) {
    .arrowonTopright .slick-arrow {
        position: absolute;
        top: -110px;
    }

    .arrowonTopright .slick-arrow.slick-prev {
        right: 70px;
        left: auto;
    }

    .arrowonTopright .slick-arrow.slick-next {
        right: 29px;
        left: auto;
    }
}

.submit-button{ 
    text-decoration: none;
    background-color: #b8353b !important;
     color:white !important;
     border-radius:5px !important;
     border:1px solid #b8353b
    }

/* #google_translate_element,
.skiptranslate{
    display: none;
} */

.subscription-wrapper{
    overflow-x: hidden;
    background-image: linear-gradient(to bottom, #b8353b 50%, #fff 50%);
}

.subscription-wrapper h2{
    font-family: 'Poppins';
}
.subscription-wrapper h2,
.subscription-wrapper p{
    color: #b8353b;
}
.subscription-wrapper h1{
    color: #888383;
}

.subscription-wrapper .hrLine{
    display: inline-block;
    height: 0.5px;
    background-color: #b8353b;
    width: 50%;
    margin-top: -1rem;
}

.subscription-wrapper button{
    background-color: transparent;
    border: 1px solid #b8353b;
    transition: all 200ms ease;
    color: #b8353b;
}
.subscription-wrapper button:hover{
    background-color: #b8353b;
    color: #fff;
}

@media(max-width:1023px){
    .subscription-wrapper .siderBorder{
        border-top: 1px solid #b8353b;
        border-bottom: 1px solid #b8353b;
    }
}
@media (min-width:1024px){
    .subscription-wrapper .siderBorder{
        border-left: 1px solid #b8353b;
        border-right: 1px solid #b8353b;
    }
}