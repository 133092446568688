.footer-links {
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer-links li {
    padding-top: 10px;
}

.footer-font{

}

.footer-links li a {
    display: inline-block;
    font-weight: 500;
    letter-spacing: .02em;
    color: #fff;
    position: relative;
    transition: all .35s;
    text-decoration: none;
}

.footer-links li a::before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: -12px;
    height: 5px;
    width: 5px;
    background: #fff;
    border-radius: 50%;
    opacity: 0;
    transition: all .35s;
}

.footer-links li a:hover {
    transform: translateX(14px);
}

.footer-links li a:hover::before {
    opacity: 1;
}

.footer-social {
    margin-top: 20px;
}

.footer-social a i {
    color: #fff;
    font-size: 18px;
    margin-right: 10px;
}


footer {
    padding-left: 20px;
    background-color: #b8353b;
}

footer h6 {
    font-family: 'Poppins';
    font-weight: 700;
    color: #fff;
}

footer .footer-connect {
    font-family: 'Poppins';
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}

@media only screen and (max-width: 576px) {
    footer .footer-connect {
        font-size: 13px !important;
    }
    
}

.footer-left-side {
    /* padding-left: 250px; */
}

footer hr {
    border-color: #fff;
}